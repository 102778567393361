<template>
  <div
    title="usePolicy"
    style="background-color: #eff3f5;height: 100%;width: 100%;overflow: scroll"
    align="center"
    overflow="scroll"
  >
    <a-card style="background-color: white;width: 1100px;margin-top: 50px;padding-left: 20px;padding-right: 20px">
      <div lang="zh-CN" link="#000080" vlink="#800000" dir="ltr">
        <div style="margin-top: 20px">
          <h3>
            <p style="font-size: 26px">可接受的使用政策</p>
          </h3>
        </div>
        <div style="margin-top: 20px">
          <h3>
            <p style="font-size: 20px">{{ themeData.saasMerNameZh }} Limited</p>
          </h3>
        </div>
        <div style="margin-top: 20px">
          <h3>
            <p style="font-size: 20px">2021 年5月</p>
          </h3>
        </div>
        <p class="cjk" align="center" style="line-height: 100%; margin-bottom: 3mm">
          <br>

        </p>
        <p class="cjk" align="center" style="line-height: 100%; margin-bottom: 3mm">
          <br>

        </p>
        <p class="cjk" align="center" style="line-height: 100%; margin-bottom: 3mm">
          <br>

        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font style="font-size: 14px"><font><span style="background: #ffffff"><font><font
            style="font-size: 14px"
          >本《可接受的使用政策》允许您访问我们服务的条款。</font></font></span></font></font></p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font style="font-size: 14px"><font><span style="background: #ffffff"><font><font
            style="font-size: 14px"
          >它在您访问和</font></font></span></font><font><font
            style="font-size: 14px"
          ><span
            lang="en-US"
          ><font><font
            style="font-size: 14px"
          >/</font></font></span></font></font><font
            style="font-size: 14px"
          ><font><font
            style="font-size: 14px"
          >或使用我们的服务后立即生效。</font></font></font></font>
        </p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p class="navigationTitle" align="left">一、禁止使用<br></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font style="font-size: 14px"><font><span style="background: #ffffff"><font><font
            style="font-size: 14px"
          >您只能在合法途径下使用我们的服务。您不得在</font></font></span></font><font><span
            style="background: #ffffff"
          ><font><font style="font-size: 14px">以</font></font></span></font><font><span style="background: #ffffff"><font><font
            style="font-size: 14px"
          >下情境中使用我们的服务：</font></font></span></font></font>
        </p>
        <ol>
          <li><p
            align="left"
            style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
          >
            <font style="font-size: 14px"><font><span style="background: #ffffff"><font><font
              style="font-size: 14px"
            >以任何违反地方、国家或国际法律或法规的方式或导致</font></font></span></font><font><font
              style="font-size: 14px"
            ><span
              lang="en-US"
            ><font><font
              style="font-size: 14px"
            >{{ themeData.saasMerNameZh }}</font></font></span></font></font><font
              style="font-size: 14px"
            ><font><font
              style="font-size: 14px"
            >违反任何适用的法律或法规；</font></font></font></font></p>
          </li>
          <li><p
            align="left"
            style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
          >
            <font style="font-size: 14px"><font><span style="background: #ffffff"><font><font
              style="font-size: 14px"
            >以任何非法或欺诈，或具有任何非法或欺诈目</font></font></span></font><font><span
              style="background: #ffffff"
            ><font><font
              style="font-size: 14px"
            >的</font></font></span></font><font><span style="background: #ffffff"><font><font
              style="font-size: 14px"
            >或影响的方式</font></font></span></font></font><font><font
              style="font-size: 14px"
            ><span lang="en-US"><font><font><font
              style="font-size: 14px"
            ><span
              style="background: #ffffff"
            >;</span></font></font></font></span></font></font></p>
          </li>
          <li><p
            align="left"
            style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
          >
            <font style="font-size: 14px"><font><span style="background: #ffffff"><font><font
              style="font-size: 14px"
            >以任何方式伤害或企图伤害未成年人的；</font></font></span></font></font></p>
          </li>
          <li><p
            align="left"
            style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
          >
            <font style="font-size: 14px"><font><span style="background: #ffffff"><font><font
              style="font-size: 14px"
            >任何滥用或不符合我们内容标准的方式；</font></font></span></font></font></p>
          </li>
          <li><p
            align="left"
            style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
          >
            <font style="font-size: 14px"><font><span style="background: #ffffff"><font><font
              style="font-size: 14px"
            >任何未经请求或未经授权的广告</font></font></span></font><font><font
              style="font-size: 14px"
            ><span lang="en-US"><font><font
              style="font-size: 14px"
            >,</font></font></span></font></font><font
              style="font-size: 14px"
            ><font><font
              style="font-size: 14px"
            >促销材料或任何其他形式垃圾邮件；</font></font></font></font>
          </p>
          </li>
          <li><p
            align="left"
            style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
          >
            <font style="font-size: 14px"><font><span style="background: #ffffff"><font><font

              style="font-size: 14px"
            >处理病毒、间谍软件等有害程序或类似的计算机代码对任何计算机防火墙软件或硬件的运行产生不利影响</font></font></span></font><font><span style="background: #ffffff"><font><font
              style="font-size: 14px"
            >；或者</font></font></span></font></font>
          </p>
          </li>
          <li><p
            align="left"
            style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
          >
            <font style="font-size: 14px"><font><span style="background: #ffffff"><font><font
              style="font-size: 14px"
            >以任何方式在当地或国际上逃避任何适用的税收或便利逃税。</font></font></span></font></font></p>
          </li>
        </ol>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p class="navigationTitle" align="left">二、我们不向提供以下交易的企业提供服务或支持涉及以下交易：<br></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >1.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >赌场、彩票、在线赌博或任何涉及资产股份的以赢得奖品</font></font></font><font><font
            style="font-size: 14px"
          ><span lang="en-US"><font><font
            style="font-size: 14px"
          >/</font></font></span></font></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >奖励等行为；</font></font></font></font></p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >2.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >可交易和</font></font></font><font><font
            style="font-size: 14px"
          ><span lang="en-US"><font><font
            style="font-size: 14px"
          >/</font></font></span></font></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >或可赎回的虚拟货币；</font></font></font></font></p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >3.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >成人娱乐和服务，包括色情、约会、三陪服务和性服务的销售和</font></font></font><font><font
            style="font-size: 14px"
          ><span lang="en-US"><font><font
            style="font-size: 14px"
          >/</font></font></span></font></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >或广告；</font></font></font></font></p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >4.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >武器和武器制造商、供应商或经销商；</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >5.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >不受监管的货币服务业务、金融或交易所；</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >6</span></font></font></font></span></font><font
            style="font-size: 14px"
          ><font><font
            style="font-size: 14px"
          >.外汇投机；</font></font></font></font></p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >7</span></font></font></font></span></font><font
            style="font-size: 14px"
          ><font><font
            style="font-size: 14px"
          >.贵金属、宝石；</font></font></font></font></p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >8.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >涉及现金的私人对私人汇款；</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >9.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >投标费用拍卖；</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >10</span></font></font></font></span></font><font
            style="font-size: 14px"
          ><font><font
            style="font-size: 14px"
          >.二手车；</font></font></font></font></p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >11.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >危险或有害的生物、化学或核材料；</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >12.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >违反适用法律或行业法规的处方药和器械；</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >13.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >被政府机构认定为欺诈的产品或服务；</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >14.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >传销或某些多层次营销计划；</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >15.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >虐待动物、活体解剖或使用动物进行任何科学测试目的</font></font></font><font><font
            style="font-size: 14px"
          ><span lang="en-US"><font><font
            style="font-size: 14px"
          >;</font></font></span></font></font></font></p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >16.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >按摩店、美甲店</font></font></font><font><font
            style="font-size: 14px"
          ><span lang="en-US"><font><font
            style="font-size: 14px"
          >/</font></font></span></font></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >美甲沙龙、夜总会、用于宗教崇拜、桑拿、土耳其浴室、吸烟俱乐部、体育博彩的场所；</font></font></font></font></p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >17.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >大使馆、外交使团和领事馆；</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >18.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >任何我们的风险偏好之外的其他业务或者违背内部交易政策、我们的银行合作伙伴的政策或我们的支付网络中的参与者的政策的业务，包括但不限于以下方面：</font></font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >(i)
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >违反任何法律、法规、条例或法规；</font></font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >(ii)
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >从事非法活动；</font></font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >(iii)
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >对犯罪的经济利用；</font></font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >(iv)
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >赃物；</font></font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >(v)
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >吸毒用具；</font></font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >(vi)
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >淫秽物品；</font></font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >(vii)
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >性用品或服务；</font></font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >(viii)
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >人体或动物器官，包括血液和血浆；</font></font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >(ix)
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >侵犯任何版权或商标；</font></font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >(x)
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >侵犯公开权或隐私权；</font></font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >(xi)
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >违反适用法律显示第三方的个人信息；</font></font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >(xii)
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >收养机构，包括代孕；</font></font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >(xiii)
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >弹药、枪支、某些枪支零件或配件；</font></font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >(xiv)
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >适用法律规定的某些武器或刀具；</font></font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >(xv)
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >雇佣兵或合同兵；</font></font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >(xvi)
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >虐待难民或人权；</font></font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >(xvii)
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >虐待动物、活体解剖或使用动物进行任何科学测试目的。</font></font></font></font>
        </p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p class="navigationTitle" align="left">三、 除非得到我们管理团队的特别批准并且尽职调查完成后的结果令人满意，否则我们将不向支持涉及以下内容交易的企业提供我们的服务：<br></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >1.
            Google play </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >或
          </font></font></font><font><font style="font-size: 14px"><span lang="en-US"><font><font
            style="font-size: 14px"
          >Appstore
          </font></font></span></font></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >提供的游戏和主播</font></font></font><font><font
            style="font-size: 14px"
          ><span lang="en-US"><font><font
            style="font-size: 14px"
          >/</font></font></span></font></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >约会应用；</font></font></font></font></p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >2.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >虚拟货币相关联的业务，如挖矿、交易所、</font></font></font><font><font
            style="font-size: 14px"
          ><span lang="en-US"><font><font
            style="font-size: 14px"
          >DeFi
          </font></font></span></font></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >等；</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >3.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >涉及商品供应的公司；</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >4.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >投资公司。</font></font></font></font>
        </p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p class="navigationTitle" align="left">四、 我们不向以下类别的实体提供我们的服务：<br></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >1.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >慈善机构，包括未注册的慈善机构；</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >2.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >信托（除非它们在加拿大、欧洲经济区、瑞士、澳大利亚、新西兰或美国成立）；</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >3.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >不记名股票或在其所有权链中设有不记名股票公司的实体；</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >4.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >匿名账户；</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >5.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >货币服务业务或从事以下活动的任何业务：</font></font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >i.</span></font></font></font></span></font><font
            style="font-size: 14px"
          ><font><font
            style="font-size: 14px"
          >兑现应付给客户的支票；</font></font></font></font></p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >ii.</span></font></font></font></span></font><font
            style="font-size: 14px"
          ><font><font
            style="font-size: 14px"
          >涉及现金的私人对私人汇款；</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >6.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >在任何国家都没有实体存在的银行（即“空壳银行”）；</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >7.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >任何我们的风险偏好之外的其他业务或者违背内部交易政策、我们的银行合作伙伴的政策或我们的支付网络中的参与者的政策的业务。</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <br>
        </p>
        <p class="navigationTitle" align="left">五、限制国家<br></p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <br></p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >1.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >位于下列国家</font></font></font><font><font
            style="font-size: 14px"
          ><span lang="en-US"><font><font
            style="font-size: 14px"
          >/</font></font></span></font></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >地区的任何一方将被拒绝。国家是由个人居住国和参与交易的公司的登记管辖地决定的；</font></font></font></font></p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >2.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >以下国家</font></font></font><font><font
            style="font-size: 14px"
          ><span lang="en-US"><font><font
            style="font-size: 14px"
          >/</font></font></span></font></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >地区的付款将被拒绝：</font></font></font></font></p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font style="font-size: 14px"><font><span style="background: #ffffff"><font><font
            style="font-size: 14px"
          >伊朗
            朝鲜</font></font></span></font></font></p>
        <p align="left" style="line-height: 140%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto">
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font style="font-size: 14px"><span style="background: #ffffff">3.</span></font></font></font></span></font>
            <font style="font-size: 14px"><font><font style="font-size: 14px">向下列国家的付款将需要进一步履行客户尽职调查（</font></font></font>
            <font><font style="font-size: 14px"><span lang="en-US"><font><font style="font-size: 14px">CDD</font></font></span></font></font>
            <font style="font-size: 14px"><font><font style="font-size: 14px">）或交易监控程序以确保交易和交易目的是合规、合法且不涉及任何制裁列出的场合。</font></font></font>
            <font><font style="font-size: 14px"><span lang="en-US"><font><font style="font-size: 14px">{{ themeData.saasMerNameZh }}</font></font></span></font></font>
            <font style="font-size: 14px"><font><font style="font-size: 14px">将要求提供更多信息或文件以进行加强尽职调查</font></font></font>
            <font><font style="font-size: 14px"><span lang="en-US"><font><font style="font-size: 14px">(EDD)</font></font></span></font></font>
            <font style="font-size: 14px"><font><font style="font-size: 14px">程序。这可能导致直接拒绝在这些地区开展业务的合作伙伴</font></font></font>
            <font><font style="font-size: 14px"><span lang="en-US"><font><font style="font-size: 14px">/</font></font></span></font></font>
            <font style="font-size: 14px"><font><font style="font-size: 14px">客户收</font></font></font>
            <font><font style="font-size: 14px"><span lang="en-US"><font><font style="font-size: 14px">/</font></font></span></font></font>
            <font style="font-size: 14px"><font><font style="font-size: 14px">付款：</font></font></font>
          </font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >i.</span></font></font></font></span></font><font
            style="font-size: 14px"
          ><font><font
            style="font-size: 14px"
          >被
          </font></font></font><font><font style="font-size: 14px"><span lang="en-US"><font><font
            style="font-size: 14px"
          >FATF
          </font></font></span></font></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >定义为不合作</font></font></font><font><font
            style="font-size: 14px"
          ><span lang="en-US"><font><font
            style="font-size: 14px"
          >/</font></font></span></font></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >缺乏反洗钱监管的国家；</font></font></font></font></p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >ii.</span></font></font></font></span></font><font
            style="font-size: 14px"
          ><font><font style="font-size: 14px">实施国际制裁的国家</font></font></font><font><font
            style="font-size: 14px"
          ><span lang="en-US"><font><font
            style="font-size: 14px"
          >/</font></font></span></font></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >实体，包括但不限于以下国家及地区：</font></font></font></font></p>
        <p
          class="use-three-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >(a)
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >美国
          </font></font></font><font><font style="font-size: 14px"><span lang="en-US"><font><font
            style="font-size: 14px"
          >OFAC</font></font></span></font></font></font>
        </p>
        <p
          class="use-three-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >(b)
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >联合国</font></font></font></font>
        </p>
        <p
          class="use-three-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >(c)
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >英国
          </font></font></font><font><font style="font-size: 14px"><span lang="en-US"><font><font
            style="font-size: 14px"
          >HMT</font></font></span></font></font></font>
        </p>
        <p
          class="use-three-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >(d)
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >欧洲联盟</font></font></font></font>
        </p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p class="navigationTitle" align="left">六、您也同意：<br></p>
        <p
          class="cjk"
          style="line-height: 100%; page-break-inside: auto; margin-bottom: 3mm; page-break-before: auto; page-break-after: auto"
        >
          <br>

        </p>
        <ol>
          <li><p
            align="left"
            class="cjk"
            style="line-height: 100%; page-break-inside: auto; margin-bottom: 3mm; page-break-before: auto; page-break-after: auto"
          >
            <font><span style="background: #ffffff"><font><font style="font-size: 14px">不得在违反我们的规定的情况下复制或使用我们所提供的服务以及客户协议的任何部分；和
            </font></font></span></font>
          </p>
          </li>
          <li><p
            align="left"
            class="cjk"
            style="line-height: 100%; page-break-inside: auto; margin-bottom: 3mm; page-break-before: auto; page-break-after: auto"
          >
            <font><span style="background: #ffffff"><font><font style="font-size: 14px">不得擅自访问、干扰、破坏或破坏：
            </font></font></span></font>
          </p>
          </li>
        </ol>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; margin-bottom: 3mm; page-break-before: auto; page-break-after: auto"
        >
          <font face="Calibri, serif"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >i.</span></font></font></font></span><font><font
            style="font-size: 14px"
          >我们服务的任何部分；</font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; margin-bottom: 3mm; page-break-before: auto; page-break-after: auto"
        >
          <font face="Calibri, serif"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >ii.</span></font></font></font></span><font><font
            style="font-size: 14px"
          >存储我们网站的任何设备或网络；</font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; margin-bottom: 3mm; page-break-before: auto; page-break-after: auto"
        >
          <font face="Calibri, serif"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >iii.</span></font></font></font></span><font><font
            style="font-size: 14px"
          >用于我们提供服务的任何软件；或者
          </font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; margin-bottom: 3mm; page-break-before: auto; page-break-after: auto"
        >
          <font face="Calibri, serif"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >iv.</span></font></font></font></span><font><font
            style="font-size: 14px"
          >任何第三方拥有或使用的任何设备、网络或软件。</font></font></font>
        </p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p class="navigationTitle" align="left">七、您只能使用您的全球帐号（我们提供给您的）来接收用于以下目的的资金：<br></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >1.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >从经批准的电子商务和市场平台接收付款；</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >2.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >出于商业目的从您的客户和其他第三方接收付款；</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >3.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >从其他合法商业来源获取自己的资金。</font></font></font></font>
        </p>
        <p
          class="cjk"
          style="line-height: 100%; page-break-inside: auto; margin-bottom: 3mm; page-break-before: auto; page-break-after: auto"
        >
          <br>

        </p>
        <p class="navigationTitle" align="left">八、您只能以您自己的名义从银行账户支付款项至我们为您提供的有关付款服务的帐户号码。<br></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p class="navigationTitle" align="left">九、您不得将我们的服务用于以下目的：<br></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >1.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >从电子货币平台</font></font></font><font><font
            style="font-size: 14px"
          ><span lang="en-US"><font><font
            style="font-size: 14px"
          >/</font></font></span></font></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >服务</font></font></font><font><font
            style="font-size: 14px"
          ><span
            lang="en-US"
          ><font><font
            style="font-size: 14px"
          >/</font></font></span></font></font><font
            style="font-size: 14px"
          ><font><font
            style="font-size: 14px"
          >供应商接收付款或取款除非其业务背景已获得某些代理银行和、或</font></font></font><font><font
            style="font-size: 14px"
          ><span lang="en-US"><font><font
            style="font-size: 14px"
          >{{ themeData.saasMerNameZh }}</font></font></span></font></font><font
            style="font-size: 14px"
          ><font><font
            style="font-size: 14px"
          >的监管机构和</font></font></font><font><font
            style="font-size: 14px"
          ><span
            lang="en-US"
          ><font><font
            style="font-size: 14px"
          >/</font></font></span></font></font><font
            style="font-size: 14px"
          ><font><font style="font-size: 14px">或合作伙伴的批准；</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >2.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >接受短期贷款人的付款；</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >3.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >除非我们批准，否则代表任何其他人进行支付或收款或您以外的实体，包括任何姊妹实体或母公司；</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >4.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >在您的全球账户上设置直接借记；</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >5.
          </span></font></font></font></span></font></font><font style="font-size: 14px"><font><span
            style="background: #ffffff"
          ><font><font
            style="font-size: 14px"
          >以可能导致对</font></font></span></font><font><font
            style="font-size: 14px"
          ><span lang="en-US"><font><font
            style="font-size: 14px"
          >{{ themeData.saasMerNameZh }}</font></font></span></font></font><font
            style="font-size: 14px"
          ><font><font
            style="font-size: 14px"
          >、</font></font></font><font><font
            style="font-size: 14px"
          ><span
            lang="en-US"
          ><font><font style="font-size: 14px">{{ themeData.saasMerNameZh }}
          </font></font></span></font></font><font style="font-size: 14px"><font><font style="font-size: 14px">的其他客户、第三者或您的投诉、纠纷、撤销、拒付或其他法律责任的方式经营业务或使用服务。</font></font></font></font>
        </p>
        <p
          class="cjk"
          style="line-height: 100%; page-break-inside: auto; margin-bottom: 3mm; page-break-before: auto; page-break-after: auto"
        >
          <br>

        </p>
        <p class="navigationTitle" align="left">十、 内容标准<br></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font style="font-size: 14px"><font><span style="background: #ffffff"><font><font
            style="font-size: 14px"
          >这些内容标准适用于您</font></font></span></font><font><span
            style="background: #ffffff"
          ><font><font
            style="font-size: 14px"
          >提供给我们的服务的</font></font></span></font><font><span style="background: #ffffff"><font><font
            style="font-size: 14px"
          >任何材料。这些内容必须：</font></font></span></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >1.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >准确；</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >2.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >真实持有（在他们陈述意见的情况下）；和</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >3.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >遵守香港、中国</font></font></font><font><span style="background: #ffffff"><font><font
            style="font-size: 14px"
          >大陆</font></font></span></font><font><span style="background: #ffffff"><font><font style="font-size: 14px">、加拿大和任何它们或与之相关国家</font></font></span></font></font><font><font
            style="font-size: 14px"
          ><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >/</span></font></font></font></span></font><font
            style="font-size: 14px"
          ><font><font
            style="font-size: 14px"
          >地区的适用法律。</font></font></font></font></p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >4.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >这些内容不得：</font></font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >i.</span></font></font></font></span></font><font
            style="font-size: 14px"
          ><font><font
            style="font-size: 14px"
          >包含任何诽谤性材料；</font></font></font></font></p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >ii.</span></font></font></font></span></font><font
            style="font-size: 14px"
          ><font><font
            style="font-size: 14px"
          >包含任何淫秽、攻击性、仇恨或煽动性的材料；</font></font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >iii.</span></font></font></font></span></font><font
            style="font-size: 14px"
          ><font><font
            style="font-size: 14px"
          >宣传色情材料；</font></font></font></font></p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >iv.</span></font></font></font></span></font><font
            style="font-size: 14px"
          ><font><font
            style="font-size: 14px"
          >宣扬暴力；</font></font></font></font></p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >v.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >促进基于种族、性别、宗教、国籍、残疾、性取向或年龄的歧视；</font></font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >vi.</span></font></font></font></span></font><font
            style="font-size: 14px"
          ><font><font
            style="font-size: 14px"
          >侵犯任何其他人的任何版权、数据库权利或商标；</font></font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >vii.</span></font></font></font></span></font><font
            style="font-size: 14px"
          ><font><font style="font-size: 14px">可能会欺骗任何人；</font></font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >viii.</span></font></font></font></span></font><font
            style="font-size: 14px"
          ><font><font
            style="font-size: 14px"
          >违反对第三方的任何法律义务，例如合同义务或保密义务；</font></font></font></font></p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >ix.</span></font></font></font></span></font><font
            style="font-size: 14px"
          ><font><font style="font-size: 14px">宣传任何非法活动；</font></font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >x.</span></font></font></font></span></font><font
            style="font-size: 14px"
          ><font><font
            style="font-size: 14px"
          >以任何方式威胁、辱骂或侵犯他人隐私，或导致烦恼、不便或不必要的焦虑；</font></font></font></font></p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >xi.</span></font></font></font></span></font><font
            style="font-size: 14px"
          ><font><font
            style="font-size: 14px"
          >可能会骚扰、不安、尴尬、惊吓或惹恼任何其他人；</font></font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >xii.</span></font></font></font></span></font><font
            style="font-size: 14px"
          ><font><font
            style="font-size: 14px"
          >被用来冒充任何人，或歪曲您的身份或与任何有隶属关系的人；</font></font></font></font>
        </p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >xiii.</span></font></font></font></span></font><font
            style="font-size: 14px"
          ><font><font
            style="font-size: 14px"
          >如果情况并非如此，则给人以他们与我们有关的印象；或者</font></font></font></font></p>
        <p
          class="use-two-title"
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >xiv.</span></font></font></font></span></font><font
            style="font-size: 14px"
          ><font><font
            style="font-size: 14px"
          >倡导、促进或协助任何非法行为，例如（仅作为示例）侵犯版权或滥用计算机。</font></font></font></font>
        </p>
        <p
          class="cjk"
          style="line-height: 100%; page-break-inside: auto; margin-bottom: 3mm; page-break-before: auto; page-break-after: auto"
        >
          <br>

        </p>
        <p class="navigationTitle" align="left">十一、 交易限额<br></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p
          align="left"
          style="line-height: 140%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font style="font-size: 14px"><font><span style="background: #ffffff"><font><font
            style="font-size: 14px"
          >除非您已获得更高限额的批准，否则您只能进行未平仓头寸或未结算的外币兑换申请时交易金额不超过
          </font></font></span></font><font><font style="font-size: 14px"><span lang="en-US"><font><font
            style="font-size: 14px"
          >10,000
          </font></font></span></font></font><font style="font-size: 14px"><font><font style="font-size: 14px">美元（或等值金额）的交易。我们可能会不时审查我们的内部信用风险政策并调整我们关于这些政策的交易限制。如果我们降低交易限额，我们将给予您对此类更改的合理通知。</font></font></font></font>
        </p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p class="navigationTitle" align="left">十二、 暂停和终止<br></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font style="font-size: 14px"><font><span style="background: #ffffff"><font><font
            style="font-size: 14px"
          >我们将通过您使用我们的服务</font></font></span></font><font><span
            style="background: #ffffff"
          ><font><font style="font-size: 14px">自行</font></font></span></font><font><span style="background: #ffffff"><font><font
            style="font-size: 14px"
          >确定是否存在违反此可接受使用政策的情况。</font></font></span></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font style="font-size: 14px"><font><span style="background: #ffffff"><font><font
            style="font-size: 14px"
          >我们会严肃对待违反本政策的行为，并可能采取以下措施：</font></font></span></font></font></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto">
          <font>
            <font style="font-size: 14px"><span lang="en-US"><font><font><font style="font-size: 14px"><span style="background: #ffffff">1.</span></font></font></font></span></font>
            <font style="font-size: 14px"><font><font style="font-size: 14px">立即、暂时或永久撤销您使用我们服务的权利；</font></font></font>
          </font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >2.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >暂停或取消您的支付订单并采取我们认为有必要的其他举措；</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >3.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >立即、临时或永久删除由您发送或上传的所有材料；</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >4.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >发出警告；</font></font></font></font>
        </p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font><font style="font-size: 14px"><span lang="en-US"><font><font><font
            style="font-size: 14px"
          ><span
            style="background: #ffffff"
          >5.
          </span></font></font></font></span></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >对您采取法律行动，包括要求在“所有费用”基础上偿还所有的诉讼费用；和</font></font></font><font><font
            style="font-size: 14px"
          ><span lang="en-US"><font><font
            style="font-size: 14px"
          >/</font></font></span></font></font><font style="font-size: 14px"><font><font
            style="font-size: 14px"
          >或</font></font></font></font></p>
        <ol start="6">
          <li><p
            align="left"
            style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
          >
            <font style="font-size: 14px"><font><span style="background: #ffffff"><font><font
              style="font-size: 14px"
            >向执法机关报告和披露信息。</font></font></span></font></font></p>
          </li>
        </ol>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p class="navigationTitle" align="left">十三、其他政策<br></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font style="font-size: 14px"><font><span style="background: #ffffff"><font><font

            style="font-size: 14px"
          >本政策并不限制我们在与您的协议中的任何权利，亦不限制我们根据内部政策、我们银行业伙伴的政策，或我们支付网络参与者的政策，实施我们的风险承受能力。</font></font></span></font></font>
        </p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p class="navigationTitle" align="left">十四、 可接受使用政策的变更<br></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p
          align="left"
          style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"
        >
          <font style="font-size: 14px"><font><span style="background: #ffffff"><font><font

            style="font-size: 14px"
          >我们可能随时通过修改此页面来修改此可接受的使用政策，而无需事先通知。您需要定期检查它，因为它对您具有法律约束力。</font></font></span></font></font>
        </p>

      </div>
    </a-card>
  </div>
</template>

<script>
import { reactive, toRefs, inject } from 'vue'
export default {
  name: 'UsePolicyCN',
  setup () {
    const state = reactive({
      themeData: inject('$themeData')
    })

    return {
      ...toRefs(state)
    }
  }
}
</script>

<style scoped>
p.navigationTitle {
  font-size: 15px;
  color: black;
  font-weight: bold;
}
p.use-two-title {
  margin-left: 20px;
}
p.use-three-title {
  margin-left: 40px;
}
</style>
